import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Joomla",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/joomla.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Joomla`}</h1>
    <p>{`Easy-to-install Live Chat for Joomla`}</p>
    <hr></hr>
    <p>{`Joomla is a popular CMS, running millions of websites. Looking for an easy way to install a Live Chat on your Joomla Website ? Using this integration, add the Crisp Livechat in a single click on Joomla! Whats more ? It's totally free !`}</p>
    <p>{`Benefits of using our Live Chat for Joomla:`}</p>
    <ul>
      <li parentName="ul">{`Add the Crisp Livechat in a single click (No Coding skills required!)`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically push user data when someone is already logged-in`}</li>
      <li parentName="ul">{`Automatically adapts to your website language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Boost users engagement thanks to Live Chat`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      